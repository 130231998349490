@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;700&family=Roboto:ital,wght@0,100;0,700;0,900;1,400&family=Rubik:ital,wght@1,700&display=swap&family=Lato");

@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css);

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-color: #000027;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.navbar-brand {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  color: #ffffff !important;
}

.link-purple__log_in {
  background-color: #18204a;
  width: 128px;
  height: 31px;
  line-height: 31px;
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  color: #ffffff !important;
  text-align: center;
  border-radius: 5px;
}

.link-purple__sign-up {
  background-color: #019cc4;
  width: 128px;
  height: 31px;
  line-height: 31px;
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  color: #ffffff !important;
  text-align: center;
  border-radius: 5px;
}

.crypto__title {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  color: #ffffff !important;
}

.crypto__description {
  color: #ffffff !important;
  font-family: "Open Sans", sans-serif;
  font-size: 1.2em;
}

.link-yellow__play {
  width: 164px;
  height: 42px;
  background-color: #c79b34;
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  color: #ffffff !important;
  text-align: center;
  border-radius: 5px;
  line-height: 42px;
}

.crypto__about h2 {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #ffffff !important;
}

.crypto__about p {
  font-family: "Open Sans", sans-serif;
  color: #ffffff !important;
  font-weight: 400;
}

.crypto__top-games {
  margin-top: 50px;
  margin-bottom: 50px;
  width: 100%;
}
.crypto__top-games h2 {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #ffffff !important;
}

.crypto-dark-card {
  background-color: #18204a !important;
  color: #ffffff !important;
  text-align: center;
}

.card-body-title {
  padding: 0px !important;
}

.card-image-container {
  position: relative;
}

.card-body-image {
  height: 150px;
  position: relative;
}

.footer {
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
}

.footer h4 {
  font-size: 2rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #ffffff !important;
}
.footer nav {
  margin-left: 10px;
}
.footer nav a {
  color: #ffffff !important;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

.footer .crypto__list-anclas a {
  display: block;
}

a {
  text-decoration: none;
  color: #fff;
}
p > a:hover {
  color: #d9d9d9;
  text-decoration: underline;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 1% 0 1% 0;
}
._12 {
  font-size: 1.2em;
}
._14 {
  font-size: 1.4em;
}
ul {
  padding: 0;
  list-style: none;
}

ul .fa {
  border-radius: 5px;
}
.footer-social-icons {
  width: 350px;
  display: block;
  margin: 0 auto;
}
.social-icon {
  color: #fff;
}
ul.social-icons {
  margin-top: 10px;
}
.social-icons li {
  vertical-align: top;
  display: inline;
  height: 100px;
}
.social-icons a {
  color: #fff;
  text-decoration: none;
}
.fa-facebook {
  padding: 10px 14px;
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #18204a;
}
.fa-facebook:hover {
  background-color: #3d5b99;
}
.fa-twitter {
  padding: 10px 12px;
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #18204a;
}
.fa-twitter:hover {
  background-color: #00aced;
}
.fa-rss {
  padding: 10px 14px;
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #18204a;
}
.fa-rss:hover {
  background-color: #eb8231;
}
.fa-youtube {
  padding: 10px 14px;
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #18204a;
}
.fa-youtube:hover {
  background-color: #e64a41;
}
.fa-linkedin {
  padding: 10px 14px;
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #18204a;
}
.fa-linkedin:hover {
  background-color: #0073a4;
}
.fa-github {
  padding: 10px 14px;
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #18204a;
}
.fa-github:hover {
  background-color: #5a32a3;
}

.fa-instagram {
  padding: 10px 14px;
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #18204a;
}

.fa-instagram:hover {
  background-color: #5a32a3;
}


.card-img-top{
  height: 180px !important;
}